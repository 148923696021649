<template>
  <div class="grid row">
    <div class="flex xs12">
      <va-card title="Slider" class="fill-height">
        <div>
          <div class="row">
            <div class="flex xs12">
              <va-tabs v-model="tabValue" style="width: 100%; min-width: 250px; margin-bottom: 25px;">
                <va-tab
                  v-for="title in tabTitles"
                  :key="title"
                >
                  {{title}}
                </va-tab>
              </va-tabs>
              <div class="flex xs12" v-if="tabValue === 0">
                <va-input
                  label="First Title *"
                  v-model="firstTitle"
                  :error="!!errors.slider_first_title"
                  :error-messages="errors.slider_first_title"
                  @input="delete errors.slider_first_title"
                />
                <va-input
                  label="First Description"
                  type="textarea"
                  v-model="firstDescription"
                  :error="!!errors.slider_first_description"
                  :error-messages="errors.slider_first_description"
                  @input="delete errors.slider_first_description"
                />
                <va-input
                  label="First Button Label"
                  v-model="firstButtonLabel"
                  :error="!!errors.slider_first_button_label"
                  :error-messages="errors.slider_first_button_label"
                  @input="delete errors.slider_first_button_label"
                />
                <va-card class="mb-3" title="First Image">
                  <div v-if="firstImageExisting">
                    <a style="display: block;" target="_blank" :href="firstImageExisting"><img style="max-width: 400px;" :src="firstImageExisting"></a>
                    <div><va-button small color="danger" @click="firstImageExisting = deleteImage(firstImageId, 'slider_first_image') ? '' : firstImageExisting">Видалити</va-button></div>
                  </div>
                  <span>Завантажити нове зображення</span>
                  <va-file-upload
                    type="gallery"
                    dropzone
                    @input="delete errors.slider_first_image"
                    v-model="firstImage"
                    style="margin-bottom: 25px;"
                  />
                  <va-button @click="uploadImage('slider_first_image', 'slider_first_image', firstImage[0])">Завантажити</va-button>
                  <div v-if="errors.slider_first_image" style="color: red;">{{ errors.slider_first_image[0] }}</div>
                </va-card>
                <va-card class="mb-3" title="First Video">
                  <div v-if="firstVideoExisting">
                    <a class="link" style="display: block;" target="_blank" :href="firstVideoExisting">{{ firstVideoExisting }}</a>
                    <div><va-button small color="danger" @click="firstVideoExisting = deleteImage(firstVideoId, 'slider_first_video') ? '' : firstVideoExisting">Видалити</va-button></div>
                  </div>
                  <span>Завантажити новий файл</span>
                  <va-file-upload
                    dropzone
                    v-model="firstVideo"
                    style="margin-bottom: 25px;"
                  />
                  <div v-if="errors.slider_first_video" style="color: red;">{{ errors.slider_first_video[0] }}</div>
                  <va-button @click="uploadImage('slider_first_video', 'slider_first_video', firstVideo[0])">Завантажити</va-button>
                </va-card>
                <va-input
                  label="Second Title *"
                  v-model="secondTitle"
                  :error="!!errors.slider_second_title"
                  :error-messages="errors.slider_second_title"
                  @input="delete errors.slider_second_title"
                />
                <va-input
                  label="Second Description"
                  type="textarea"
                  v-model="secondDescription"
                  :error="!!errors.slider_second_description"
                  :error-messages="errors.slider_second_description"
                  @input="delete errors.slider_second_description"
                />
                <va-input
                  label="Second Button Label"
                  v-model="secondButtonLabel"
                  :error="!!errors.slider_second_button_label"
                  :error-messages="errors.slider_second_button_label"
                  @input="delete errors.slider_second_button_label"
                />
                <va-card class="mb-3" title="Second Image">
                  <div v-if="secondImageExisting">
                    <a style="display: block;" target="_blank" :href="secondImageExisting"><img style="max-width: 400px;" :src="secondImageExisting"></a>
                    <div><va-button small color="danger" @click="secondImageExisting = deleteImage(secondImageId, 'slider_second_image') ? '' : secondImageExisting">Видалити</va-button></div>
                  </div>
                  <span>Завантажити нове зображення</span>
                  <va-file-upload
                    type="gallery"
                    dropzone
                    @input="delete errors.slider_second_image"
                    v-model="secondImage"
                    style="margin-bottom: 25px;"
                  />
                  <va-button @click="uploadImage('slider_second_image', 'slider_second_image', secondImage[0])">Завантажити</va-button>
                  <div v-if="errors.slider_second_image" style="color: red;">{{ errors.slider_second_image[0] }}</div>
                </va-card>
                <va-card class="mb-3" title="Second Video">
                  <div v-if="secondVideoExisting">
                    <a class="link" style="display: block;" target="_blank" :href="secondVideoExisting">{{ secondVideoExisting }}</a>
                    <div><va-button small color="danger" @click="secondVideoExisting = deleteImage(secondVideoId, 'slider_second_video') ? '' : secondVideoExisting">Видалити</va-button></div>
                  </div>
                  <span>Завантажити новий файл</span>
                  <va-file-upload
                    dropzone
                    v-model="secondVideo"
                    style="margin-bottom: 25px;"
                  />
                  <div v-if="errors.slider_second_video" style="color: red;">{{ errors.slider_second_video[0] }}</div>
                  <va-button @click="uploadImage('slider_second_video', 'slider_second_video', secondVideo[0])">Завантажити</va-button>
                </va-card>
                <va-input
                  label="Third Title *"
                  v-model="thirdTitle"
                  :error="!!errors.slider_third_title"
                  :error-messages="errors.slider_third_title"
                  @input="delete errors.slider_third_title"
                />
                <va-input
                  label="Third Description"
                  type="textarea"
                  v-model="thirdDescription"
                  :error="!!errors.slider_third_description"
                  :error-messages="errors.slider_third_description"
                  @input="delete errors.slider_third_description"
                />
                <va-input
                  label="Third Button Label"
                  v-model="thirdButtonLabel"
                  :error="!!errors.slider_third_button_label"
                  :error-messages="errors.slider_third_button_label"
                  @input="delete errors.slider_third_button_label"
                />
                <va-card class="mb-3" title="Third Image">
                  <div v-if="thirdImageExisting">
                    <a style="display: block;" target="_blank" :href="thirdImageExisting"><img style="max-width: 400px;" :src="thirdImageExisting"></a>
                    <div><va-button small color="danger" @click="thirdImageExisting = deleteImage(thirdImageId, 'slider_third_image') ? '' : thirdImageExisting">Видалити</va-button></div>
                  </div>
                  <span>Завантажити нове зображення</span>
                  <va-file-upload
                    type="gallery"
                    dropzone
                    @input="delete errors.slider_third_image"
                    v-model="thirdImage"
                    style="margin-bottom: 25px;"
                  />
                  <va-button @click="uploadImage('slider_third_image', 'slider_third_image', thirdImage[0])">Завантажити</va-button>
                  <div v-if="errors.slider_third_image" style="color: red;">{{ errors.slider_third_image[0] }}</div>
                </va-card>
                <va-card class="mb-3" title="Third Video">
                  <div v-if="thirdVideoExisting">
                    <a class="link" style="display: block;" target="_blank" :href="thirdVideoExisting">{{ thirdVideoExisting }}</a>
                    <div><va-button small color="danger" @click="thirdVideoExisting = deleteImage(thirdVideoId, 'slider_third_video') ? '' : thirdVideoExisting">Видалити</va-button></div>
                  </div>
                  <span>Завантажити новий файл</span>
                  <va-file-upload
                    dropzone
                    v-model="thirdVideo"
                    style="margin-bottom: 25px;"
                  />
                  <div v-if="errors.slider_third_video" style="color: red;">{{ errors.slider_third_video[0] }}</div>
                  <va-button @click="uploadImage('slider_third_video', 'slider_third_video', thirdVideo[0])">Завантажити</va-button>
                </va-card>
                <va-input
                  label="SEO Title"
                  v-model="seoTitle"
                  :error="!!errors.home_seo_title"
                  :error-messages="errors.home_seo_title"
                  @input="delete errors.home_seo_title"
                />
                <va-input
                  label="SEO Description"
                  type="textarea"
                  v-model="seoDescription"
                  :error="!!errors.home_seo_description"
                  :error-messages="errors.home_seo_description"
                  @input="delete errors.home_seo_description"
                />
                <va-input
                  label="SEO OpenGraph Title"
                  v-model="seoOpenGraphTitle"
                  :error="!!errors.home_seo_ogtitle"
                  :error-messages="errors.home_seo_ogtitle"
                  @input="delete errors.home_seo_ogtitle"
                />
                <va-input
                  type="textarea"
                  label="SEO OpenGraph Description"
                  v-model="seoOpenGraphDescription"
                  :error="!!errors.home_seo_ogdescription"
                  :error-messages="errors.home_seo_ogdescription"
                  @input="delete errors.home_seo_ogdescription"
                />
                <va-card class="mb-3" title="SEO OpenGraph Image">
                  <div v-if="seoOpenGraphImageExisting">
                    <a style="display: block;" target="_blank" :href="seoOpenGraphImageExisting"><img style="max-width: 400px;" :src="seoOpenGraphImageExisting"></a>
                    <div><va-button small color="danger" @click="seoOpenGraphImageExisting = deleteImage(seoOpenGraphImageId, 'slider_seo_ogimage') ? '' : seoOpenGraphImageExisting">Видалити</va-button></div>
                  </div>
                  <span>Завантажити нове зображення</span>
                  <va-file-upload
                    type="gallery"
                    dropzone
                    @input="delete errors.slider_seo_ogimage"
                    v-model="seoOpenGraphImage"
                    style="margin-bottom: 25px;"
                  />
                  <va-button @click="uploadImage('slider_seo_ogimage', 'slider.seo.ogimage', seoOpenGraphImage[0])">Завантажити</va-button>
                  <div v-if="errors.slier_seo_ogimage" style="color: red;">{{ errors.slider_seo_ogimage[0] }}</div>
                </va-card>
                <va-button
                  @click="validate()"
                >Save</va-button>
              </div>
            </div>
          </div>
        </div>
      </va-card>
    </div>
  </div>
</template>

<script>
import * as axios from 'axios'
import Vue from 'vue'

export default {
  name: 'grid',
  data () {
    return {
      firstTitle: '',
      firstDescription: '',
      firstButtonLabel: '',
      firstImage: [],
      firstImageId: '',
      firstImageExisting: '',
      firstVideo: [],
      firstVideoId: '',
      firstVideoExisting: '',
      secondTitle: '',
      secondDescription: '',
      secondButtonLabel: '',
      secondImage: [],
      secondImageId: '',
      secondImageExisting: '',
      secondVideo: [],
      secondVideoId: '',
      secondVideoExisting: '',
      thirdTitle: '',
      thirdDescription: '',
      thirdButtonLabel: '',
      thirdImage: [],
      thirdImageId: '',
      thirdImageExisting: '',
      thirdVideo: [],
      thirdVideoId: '',
      thirdVideoExisting: '',

      seoTitle: '',
      seoDescription: '',
      seoOpenGraphTitle: '',
      seoOpenGraphDescription: '',
      seoOpenGraphImage: [],
      seoOpenGraphImageId: '',
      seoOpenGraphImageExisting: '',

      errors: [],

      tabTitles: ['Main'],
      tabValue: 0,
    }
  },
  created () {
    this.fetch()
  },
  computed: {
    computedStyle () {
      return {
        backgroundColor: this.$themes.primary,
      }
    },
    locale () {
      return Vue.i18n.locale()
    },
    putData () {
      return {
        slider_first_title: this.firstTitle,
        slider_first_description: this.firstDescription,
        slider_first_button_label: this.firstButtonLabel,

        slider_second_title: this.secondTitle,
        slider_second_description: this.secondDescription,
        slider_second_button_label: this.secondButtonLabel,

        slider_third_title: this.thirdTitle,
        slider_third_description: this.thirdDescription,
        slider_third_button_label: this.thirdButtonLabel,

        slider_seo_title: this.seoTitle,
        slider_seo_description: this.seoDescription,
        slider_seo_ogtitle: this.seoOpenGraphTitle,
        slider_seo_ogdescription: this.seoOpenGraphDescription,
      }
    },
  },
  watch: {
    locale: function (value) {
      this.fetch()
    },
  },
  methods: {
    uploadImage (param, key, file, agg = 'slider') {
      if (!file) {
        return
      }
      const formData = new FormData()
      formData.append(param, file)
      formData.append('lang', this.locale)
      formData.append('aggregate', agg)
      formData.append('key', key)
      formData.append('multiple', '0')
      formData.append('file_id', '0')
      axios.post(`${process.env.VUE_APP_AGRO_API_URL}/admin/pages/upload/15?lang=${this.locale}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then(response => {
          this.showToast('Successful upload')
        })
        .catch(error => {
          console.log(error)
          this.showToast('Error')
        })
    },
    async deleteImage (id, fieldKey) {
      return axios.post(`${process.env.VUE_APP_AGRO_API_URL}/admin/pages/15/delete-file/${id}?lang=${this.locale}`, {
        key: fieldKey,
      })
        .then(() => {
          this.showToast('Successfully deleted')
        })
        .catch(() => {
          this.showToast('Error')
        })
    },
    submit () {
      axios.put(`${process.env.VUE_APP_AGRO_API_URL}/admin/pages/15?lang=${this.locale}`, this.putData)
        .then(response => {
          this.showToast('Successful update')
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          }
          this.showToast('Error')
        })
    },
    validate () {
      axios.put(`${process.env.VUE_APP_AGRO_API_URL}/admin/pages/validate/15?lang=${this.locale}`, this.putData, { headers: { 'X-Requested-With': 'XMLHttpRequest' } })
        .then(response => {
          this.errors = response.data
          if (!Object.keys(this.errors).length) {
            this.submit()
          }
        })
        .catch(error => {
          console.log(error)
          this.showToast('Validate Fetch Error')
        })
    },
    fetch () {
      axios.get(`${process.env.VUE_APP_AGRO_API_URL}/admin/pages/15?lang=${this.locale}`)
        .then(response => {
          this.firstTitle = response.data.slider_first_title
          this.firstDescription = response.data.slider_first_description
          this.firstButtonLabel = response.data.slider_first_button_label
          this.firstImageExisting = response.data.slider_first_image ? process.env.VUE_APP_AGRO_ADMIN + response.data.slider_first_image : null
          this.firstImageId = response.data.slider_first_image_id
          this.firstVideoExisting = response.data.slider_first_video ? process.env.VUE_APP_AGRO_ADMIN + response.data.slider_first_video : null
          this.firstVideoId = response.data.slider_first_video_id

          this.secondTitle = response.data.slider_second_title
          this.secondDescription = response.data.slider_second_description
          this.secondButtonLabel = response.data.slider_second_button_label
          this.secondImageExisting = response.data.slider_second_image ? process.env.VUE_APP_AGRO_ADMIN + response.data.slider_second_image : null
          this.secondImageId = response.data.slider_second_image_id
          this.secondVideoExisting = response.data.slider_second_video ? process.env.VUE_APP_AGRO_ADMIN + response.data.slider_second_video : null
          this.secondVideoId = response.data.slider_second_video_id

          this.thirdTitle = response.data.slider_third_title
          this.thirdDescription = response.data.slider_third_description
          this.thirdButtonLabel = response.data.slider_third_button_label
          this.thirdImageExisting = response.data.slider_third_image ? process.env.VUE_APP_AGRO_ADMIN + response.data.slider_third_image : null
          this.thirdImageId = response.data.slider_third_image_id
          this.thirdVideoExisting = response.data.slider_third_video ? process.env.VUE_APP_AGRO_ADMIN + response.data.slider_third_video : null
          this.thirdVideoId = response.data.slider_third_video_id

          this.seoTitle = response.data.slider_seo_title
          this.seoDescription = response.data.slider_seo_description
          this.seoOpenGraphTitle = response.data.slider_seo_ogtitle
          this.seoOpenGraphDescription = response.data.slider_seo_ogdescription
          this.seoOpenGraphImageExisting = response.data.slider_seo_ogimage ? process.env.VUE_APP_AGRO_ADMIN + response.data.slider_seo_ogimage : null
          this.seoOpenGraphImageId = response.data.slider_seo_ogimage_id
        })
        .catch(error => {
          console.log(error.response)
        })
    },
  },
}
</script>

<style lang="scss">
.grid {
  &__container {
    min-height: 3rem;
    color: $white;
    border-radius: 0.5rem;
  }
}

.va-file-upload-gallery-item {
  flex-basis: unset;
  max-width: 400px;
  width: unset;
}
</style>
